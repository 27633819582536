import locale from '../../i18n/pt-BR.json'
import logo from '../../assets/img/logo.png'
import '../../assets/css/menu.css'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ModalService from './ModalService';

export default function Menu(props: any) {
    const numero = '5511983940219'; // Exemplo de número com DDI e DDD
    const mensagem = 'Estou%20vindo%20através%20do%20site,%20gostaria%20de%20mais%20informações.';

    // URL para abrir o WhatsApp com a mensagem
    const urlWhatsApp = `https://api.whatsapp.com/send?phone=${numero}&text=${mensagem}`;
    const [activeLink, setActiveLink] = useState<string>('');
    const [dataModalService, setDataModalService] = useState<{ id: string, title: string, checked: boolean } | null>({
        id: '', title: '', checked: false
    })
    const navigate = useNavigate();

    useEffect(() => {
        const savedActiveLink = localStorage.getItem('btnActive');
        if (savedActiveLink) {
            setActiveLink(savedActiveLink);
        }
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        const href = event.currentTarget.getAttribute('href')
        localStorage.setItem('btnActive', `${href}`)
        navigate(`${href}`)
    };

    const serviceItem = (href: string) => {
        const id = href.split('#')[1]
        let data = {
            id: id,
            title: '',
            checked: true
        }

        if (data.id == 'Espiral-e-Wire-o') {
            data.title = locale.menu.service.subItem['espiralWire-o']
            setDataModalService(data)
        }
    }

    return (
        <>
            {dataModalService?.checked == false ? <ModalService id={dataModalService?.id} title={dataModalService?.title} /> : ''}
            <nav className="navbar bg-dark border-bottom border-body navbar-expand-lg navbar-expand-md fixed-top" data-bs-theme="dark">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo} alt="" className='logoMenu' />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className={`nav-link ${activeLink === '/' ? 'active' : ''}`} aria-current="page" href="/" onClick={handleClick} >
                                    {locale.menu.home.toUpperCase()}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${activeLink === '/sobre-nos' ? 'active' : ''}`} href="/sobre-nos" onClick={handleClick}>
                                    {locale.menu.sobreNos.toUpperCase()}
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="risco"></div> {/* Adiciona o risco correndo */}
                </div>
            </nav>
            <a href={urlWhatsApp} target="_blank" rel="noopener noreferrer" className="zapzap">
                <i className="bi bi-whatsapp"></i>
            </a>
        </>
    )
}