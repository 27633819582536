import Menu from "../global/Menu"
import '../../assets/css/global.css'
import locale from '../../i18n/pt-BR.json'
import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useState, useEffect } from 'react';
import SplashScreen from '../global/SplashScreen'
import ler from '../../assets/img/Story Instagram - Frase Citação Livros no Papel Rasgado.png'
import anoNovo from '../../assets/img/Brown and Black Happy New Year Instagram Post.png'
export default function Home(props: any) {
  const [location, setLocation] = useState<{ latitude: number | null, longitude: number | null }>({ latitude: null, longitude: null });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const handleLoaded = () => {
    setIsLoaded(true);
  };
  const checkLocation = () => {
    // Verifica se o navegador suporta a API de geolocalização
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Sucesso: define latitude e longitude
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          // Falha: define a mensagem de erro
          setErrorMessage(`Erro ao obter localização: ${error.message}`);
        }
      );
    } else {
      setErrorMessage("Geolocalização não é suportada neste navegador.");
    }
  }
  useEffect(() => {
    checkLocation()
  }, [location.latitude, location.longitude]);

  if (location.latitude == null && location.longitude == null) {
    checkLocation()
  } else {
    console.log('tem localização')
  }

    const [visible, setVisible] = useState(true);
    useEffect(() => {
      const timer = setTimeout(() => {
        setVisible(false); // Oculta a mensagem após 10 segundos
      }, 20000);

      return () => clearTimeout(timer); // Limpa o temporizador se o componente for desmontado
    }, []);
    return (

      <>
        {!isLoaded && <SplashScreen onLoaded={handleLoaded} />}
        {isLoaded && (
          <div>
            {/* Conteúdo principal do seu app */}
            <Menu />
          </div>
        )}
        <div className="margem container-fluid">
          <div className="row">
            <div className="col-12">
              {
                visible && (
                  <div className="alert alert-danger" id="alert" role="alert">
                    <b>Atenção</b>, para sua maior <b>experiência</b> é só <b>ativar o full screen</b> da apresentação e se estiver <b>usando smartphone</b> colocar em horizontal {"<->"}
                  </div>
                )
              }
            </div>
            <div className="col-12">
              <div style={{ position: 'relative', width: '100%', height: 0, paddingTop: '56.2500%', paddingBottom: 0, boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)', marginTop: '0', marginBottom: '0.9em', overflow: 'hidden', borderRadius: '8px', willChange: 'transform' }}>
                <iframe loading="lazy" style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, border: 'none', padding: 0, margin: 0 }}
                  src="https://www.canva.com/design/DAGY6ZcIOg8/ohsTwuI4rG6ERXnQW5RucQ/view?embed" allowFullScreen={true} allow="fullScreen">
                </iframe>
              </div>
            </div>
           
            <div className="col-12 d-block d-md-none">
              <img src={anoNovo} alt="desconto misterioso" style={{width: '100%'}}/>
            </div>
          </div>
        </div>
      </>
    )
  }