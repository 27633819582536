import React, { useEffect, useState } from "react";
import "../../assets/css/global.css";
import logo from '../../assets/img/logo.png'

interface SplashScreenProps {
  onLoaded: () => void;
}

const SplashScreen: React.FC<SplashScreenProps> = ({ onLoaded }) => {
  useEffect(() => {
    // Simular um tempo de carregamento (substitua com lógica real, se necessário)
    const timer = setTimeout(() => {
      onLoaded();
    }, 3000); // Tempo de exibição (3 segundos)

    return () => clearTimeout(timer); // Cleanup do timer
  }, [onLoaded]);

  return (
    <div className="splash-screen">
      <img src={logo} alt="Carregando" className="splash-image" />
    </div>
  );
};

export default SplashScreen;
